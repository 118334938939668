var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.courseDrafts,"loading":_vm.isLoadingCourseDrafts,"server-items-length":_vm.pagination.itemsTotalCount,"sort-by":_vm.pagination.sortBy,"sort-desc":_vm.pagination.sortDesc,"must-sort":"","hide-default-footer":"","hide-default-header":"","headers-length":3},on:{"update:sortBy":function($event){return _vm.$set(_vm.pagination, "sortBy", $event)},"update:sort-by":function($event){return _vm.$set(_vm.pagination, "sortBy", $event)},"update:sortDesc":function($event){return _vm.$set(_vm.pagination, "sortDesc", $event)},"update:sort-desc":function($event){return _vm.$set(_vm.pagination, "sortDesc", $event)},"click:row":function($event){return _vm.$router.push(("/courses/create?draftId=" + ($event.id)))}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name ? item.name : '(no name)')+" ")]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.createdAt))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"red","loading":_vm.isDeletingCourseDrafts[item.id]},on:{"click":function($event){$event.stopPropagation();return _vm.deleteCourseDraft(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete")])])]}},{key:"top",fn:function(){return [_c('div',{staticClass:"table-top px-6"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"9"}},[_c('div',{staticClass:"headline primary--text"},[_vm._v("Course Drafts")])]),_c('v-col',{staticClass:"d-flex align-center justify-end",attrs:{"cols":"12","md":"3"}},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"secondary","icon":"","small":"","loading":_vm.isReloadingCourseDrafts},on:{"click":function($event){return _vm.reloadCourseDrafts()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-refresh")])],1)]}}])},[_c('span',[_vm._v("Reload")])]),_c('div',{staticClass:"caption text-right"},[_vm._v(" showing "+_vm._s(_vm.courseDrafts.length)+" of "+_vm._s(_vm.pagination.itemsTotalCount)+" results ")])],1)],1)],1)]},proxy:true}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }